import React, { useState, FC, useCallback, useRef, useMemo } from "react";
import { Formik, FormikProps } from "formik";
import { FormGenerator, Button } from "@shared/components";
import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { WheelsFilter } from "@shared/interfaces";
import { Vehicle, WheelAdvancedFilterDataInterface } from "@shared/models";

import { getFields, handlers } from "./formHelpers";

interface WheelsListAdvancedFilterFormProps {
  onClose: () => void;
  onSubmit: (values: Partial<WheelsFilter>) => void;
  onChange: () => void;
  filter: WheelsFilter;
  wheelsAdvancedFilterData?: WheelAdvancedFilterDataInterface;
  vehicles: Vehicle[] | null;
}

const WheelsListAdvancedFilterForm: FC<WheelsListAdvancedFilterFormProps> = ({
  onClose,
  onSubmit,
  filter,
  wheelsAdvancedFilterData,
  vehicles,
  onChange,
}) => {
  const [vehicleId, setVehicleId] = useState(filter.vehicle_id);

  const formikRef = useRef<FormikProps<WheelsFilter>>(null);

  const fields = useMemo(
    () => getFields(vehicleId, wheelsAdvancedFilterData, vehicles),
    [wheelsAdvancedFilterData, vehicles, vehicleId],
  );

  const handleChangeFields = useCallback((name: string) => {
    if (name === "vehicle_id") {
      setTimeout(() => {
        formikRef.current?.setFieldValue("vehicle_model_id", null);
      }, 0);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    formikRef.current?.submitForm();
  }, []);

  const handleClearAll = useCallback(() => {
    formikRef.current?.setValues({
      is_available_on_stock: true,
      page: filter.page,
      limit: filter.limit,
      search: filter.search,
      vehicle_id: undefined,
      vehicle_model_id: undefined,
      tire_size_id: undefined,
      rim_size: undefined,
      base_size: undefined,
      base_number_of_pieces: undefined,
      bolt_circle: undefined,
      bolt_diameter: undefined,
      number_of_hand_holes: undefined,
      number_of_bolts: undefined,
      stud_size: undefined,
      inner_dimension: undefined,
      custom_oem: undefined,
    });
  }, [filter]);

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mt="10px" mb="20px">
        <Heading size="md">Advanced Filter</Heading>
        <Flex gap="15px">
          <Button padding={0} color="brand.black" variant="ghost" onClick={() => handleClearAll()}>
            Clear All
          </Button>
          <Button variant="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" leftIcon={<CheckIcon ml="8px" />} onClick={handleSubmit}>
            Apply
          </Button>
        </Flex>
      </Flex>
      <Formik
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
        initialValues={filter}
        validate={(value) => {
          onChange();
          setVehicleId(value.vehicle_id);
        }}
      >
        {(formikProps) => (
          <FormGenerator
            onChangeField={handleChangeFields}
            fields={fields}
            formikProps={formikProps}
            handlers={handlers}
          ></FormGenerator>
        )}
      </Formik>
    </Box>
  );
};

export default WheelsListAdvancedFilterForm;
